<template>
  <Layout>
    <div class="card p-5">
      <form-wizard>
        <tab-content title="Vehicle Image" :before-change="addVehicleImages">
          <FormulateForm @submit="sendData">
            <div class="text-center mb-3">Please upload vehicle images</div>
            <div class="row">
              <div class="col-sm-6">
                <div class="upload-with-preview" @click="selectImage('front')">
                  <b-spinner
                    v-if="images.front.uploading"
                    style="
                      position: absolute;
                      top: 51px;
                      left: calc(50% - 16px);
                    "
                    type="grow"
                    label="Spinning"
                  ></b-spinner>
                  <img
                    v-if="images.front.preview"
                    class="previewImg"
                    :src="images.front.preview"
                    alt="Add image"
                    srcset=""
                  />
                  <div v-else>
                    <img
                      class="take-pic"
                      :src="require('@/assets/images/take-photo.jpg')"
                      alt="Add image"
                    />
                    <div class="text-center">Front image</div>
                  </div>

                  <input
                    type="file"
                    ref="front"
                    style="visibility: hidden"
                    @input="addImage($event, 'front')"
                    accept="image/*;capture=camera"
                  />
                </div>
              </div>
              <div class="col-sm-6">
                <div class="upload-with-preview" @click="selectImage('back')">
                  <b-spinner
                    v-if="images.back.uploading"
                    style="
                      position: absolute;
                      top: 51px;
                      left: calc(50% - 16px);
                    "
                    type="grow"
                    label="Spinning"
                  ></b-spinner>
                  <img
                    v-if="images.back.preview"
                    class="previewImg"
                    :src="images.back.preview"
                    alt="Add image"
                    srcset=""
                  />
                  <div v-else>
                    <img
                      class="take-pic"
                      :src="require('@/assets/images/take-photo.jpg')"
                      alt="Add image"
                    />

                    <div class="text-center">Back image</div>
                  </div>

                  <input
                    type="file"
                    ref="back"
                    style="visibility: hidden"
                    @input="addImage($event, 'back')"
                    accept="image/*;capture=camera"
                  />
                </div>
              </div>
              <div class="col-sm-6">
                <div class="upload-with-preview" @click="selectImage('left')">
                  <b-spinner
                    v-if="images.left.uploading"
                    style="
                      position: absolute;
                      top: 51px;
                      left: calc(50% - 16px);
                    "
                    type="grow"
                    label="Spinning"
                  ></b-spinner>
                  <img
                    v-if="images.left.preview"
                    class="previewImg"
                    :src="images.left.preview"
                    alt="Add image"
                    srcset=""
                  />
                  <div v-else>
                    <img
                      class="take-pic"
                      :src="require('@/assets/images/take-photo.jpg')"
                      alt="Add image"
                    />
                    <div class="text-center">Left image</div>
                  </div>

                  <input
                    type="file"
                    ref="left"
                    style="visibility: hidden"
                    @input="addImage($event, 'left')"
                    accept="image/*;capture=camera"
                  />
                </div>
              </div>
              <div class="col-sm-6">
                <div class="upload-with-preview" @click="selectImage('right')">
                  <b-spinner
                    v-if="images.right.uploading"
                    style="
                      position: absolute;
                      top: 51px;
                      left: calc(50% - 16px);
                    "
                    type="grow"
                    label="Spinning"
                  ></b-spinner>
                  <img
                    v-if="images.right.preview"
                    class="previewImg"
                    :src="images.right.preview"
                    alt="Add image"
                    srcset=""
                  />
                  <div v-else>
                    <img
                      class="take-pic"
                      :src="require('@/assets/images/take-photo.jpg')"
                      alt="Add image"
                    />
                    <div class="text-center">Right image</div>
                  </div>

                  <input
                    type="file"
                    ref="right"
                    style="visibility: hidden"
                    @input="addImage($event, 'right')"
                    accept="image/*;capture=camera"
                  />
                </div>
              </div>
            </div>
          </FormulateForm>
        </tab-content>
        <tab-content
          title="ODO Meter Reading"
          :before-change="takeOdoMeterReading"
        >
          <div class="text-center" v-if="odo_meter.uploading">
            <img
              style="
                width: 108px;
                display: block;
                margin: auto;
                border-radius: 8px;
              "
              src="@/assets/images/partner/speedo-meter.gif"
              alt=""
              srcset=""
            />
            <strong>Taking ODO Meter reading ...</strong><br />
          </div>

          <div
            style="
              max-width: 320px;
              margin: auto;
              margin-top: 28px;
              margin-bottom: 18px;
            "
          >
            <FormulateForm ref="odoMeterForm">
              <FormulateInput
                type="number"
                name="meter_count"
                v-model="odo_meter.meter_reading"
                label="ODO Meter count"
                validation="required|number"
                min="0"
              />

              <FormulateInput
                type="select"
                name="rating"
                :options="rating"
                v-model="odo_meter.rating"
                validation="required"
                label="Condition"
              />
            </FormulateForm>

            <div class="upload-with-preview" @click="selectImage('odometer')">
              <img
                v-if="odo_meter.preview"
                class="previewImg"
                :src="odo_meter.preview"
                alt="Add image"
                srcset=""
              />
              <div v-else>
                <img
                  class="take-pic"
                  :src="require('@/assets/images/take-photo.jpg')"
                  alt="Add image"
                />
                <div class="text-center">ODO Meter</div>
              </div>

              <input
                type="file"
                ref="odometer"
                style="visibility: hidden"
                @input="addImage($event, 'odometer')"
                accept="image/*;capture=camera"
              />
            </div>
            <div
              v-if="!odo_meter.preview && showOdoMeterError"
              style="color: red; text-align: center"
            >
              Please upload odo meter image!
            </div>
          </div>
        </tab-content>
        <tab-content title="Success">
          <div class="card p-5 text-center">
            <h5 class="mt-5">Thank You!</h5>
            <p>Wrapping Job has Finished</p>
            <div class="mb-5">
              <button
                @click="
                  $router.push({ name: 'ScheduledJob', params: { id: 2 } })
                "
                style="width: 180px"
                class="primary-bseen-btn m-auto"
              >
                View ongoing jobs
              </button>
              <br />
              <button
                @click="$router.push({ name: 'ScheduledJob' })"
                style="width: 180px"
                class="secondary-bseen-btn m-auto"
              >
                Home
              </button>
            </div>
          </div>
        </tab-content>
      </form-wizard>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main";
import { FormWizard, TabContent } from "vue-form-wizard";
import { job } from "@/config/api/partner/job";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import { getFormData } from "@/utils/createForm";
import { rating } from "@/data/rating";
import { capitalizeFirstLetter } from "@/utils/capitalizeFirstLetter";
export default {
  components: {
    Layout,
    FormWizard,
    TabContent,
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
  },
  data() {
    return {
      rating: rating,
      showOdoMeterError: false,
      images: {
        front: {
          ad_id: this.$route.params.id,
          description: "front",
          file: undefined,
          preview: undefined,
          uploading: false,
        },
        back: {
          ad_id: this.$route.params.id,
          description: "back",
          file: undefined,
          preview: undefined,
          uploading: false,
        },
        right: {
          ad_id: this.$route.params.id,
          description: "right",
          file: undefined,
          preview: undefined,
          uploading: false,
        },
        left: {
          ad_id: this.$route.params.id,
          description: "left",
          file: undefined,
          preview: undefined,
          uploading: false,
        },
      },
      odo_meter: {
        ad_id: this.$route.params.id,
        meter_reading: 0,
        meter_image: undefined,
        rating: undefined,
        preview: undefined,
        uploading: false,
      },
    };
  },
  methods: {
    sendData(val) {
      console.log(val);
    },
    addImage($event, type) {
      let file = $event.target.files;

      if (file && file[0]) {
        if (type === "odometer") {
          this.odo_meter.meter_image = file[0];
          let reader = new FileReader();
          reader.onload = ($event) => {
            this.odo_meter.preview = $event.target.result;
          };
          reader.readAsDataURL(file[0]);
        } else {
          this.images[type].file = file[0];
          let reader = new FileReader();
          reader.onload = ($event) => {
            this.images[type].preview = $event.target.result;
          };
          reader.readAsDataURL(file[0]);
          this.uploadCarImage(this.images[type], type);
        }
      }
    },
    selectImage(val) {
      this.$refs[val].click();
    },
    addVehicleImages() {
      console.log(this.images);
      if (this.checkIsEveryImageUploaded()) {
        this.$bvToast.toast("Please upload vehicle images", {
          title: "Image Upload",
          variant: "danger",
          solid: true,
        });
      } else if (this.checkForUploadedInprogress()) {
        this.$bvToast.toast("Upload in progress..", {
          title: "Please wait",
          variant: "danger",
          solid: true,
        });
      } else {
        return true;
      }
      return false;
    },
    imageUploadSuccess(type) {
      this.$bvToast.toast(`${capitalizeFirstLetter(type)} image uploaded`, {
        title: "Image Upload",
        variant: "success",
        solid: true,
      });
    },
    imageUploadError(type) {
      this.$bvToast.toast(
        `${capitalizeFirstLetter(type)} image is not uploaded!`,
        {
          title: "Image Upload",
          variant: "danger",
          solid: true,
        }
      );
    },
    checkForUploadedInprogress() {
      var errorAvailable = false;
      for (const [key] of Object.entries(this.images)) {
        if (this.images[key].uploading === true) {
          errorAvailable = true;
          break;
        }
      }
      return errorAvailable;
    },
    checkIsEveryImageUploaded() {
      var errorAvailable = false;
      for (const [key] of Object.entries(this.images)) {
        if (this.images[key].file === undefined) {
          errorAvailable = true;
          break;
        }
      }
      return errorAvailable;
    },
    uploadCarImage(data, type) {
      data.uploading = true;
      const api = job.uploadCarImage;
      api.data = getFormData(data);
      this.generateAPI(api)
        .then(() => {
          this.images[type].uploading = false;
          this.imageUploadSuccess(type);
        })
        .catch(() => {
          this.imageUploadError(type);
        });
    },
    async takeOdoMeterReading() {
      this.$refs["odoMeterForm"].showErrors();
      this.showOdoMeterError = true;
      if (this.$refs["odoMeterForm"].isValid) {
        const api = job.finishWork;
        const data = { ...this.odo_meter };
        this.odo_meter.uploading = true;
        delete data.preview;
        api.data = getFormData(data);
        return await this.generateAPI(api)
          .then(() => {
            this.$bvToast.toast("Job Finished successfully", {
              title: "Finish job",
              variant: "success",
              solid: true,
            });
            return true;
          })
          .catch((err) => {
            this.$bvToast.toast(`Reading failed,${err.response.data.error}!`, {
              title: "FInish job",
              variant: "danger",
              solid: true,
            });
            return false;
          })
          .finally(() => {
            this.odo_meter.uploading = false;
          });
      } else {
        return;
      }
    },
  },
};
</script>

<style>
.wizard-icon-circle {
  border: 2px solid black !important;
  width: 58px !important;
  height: 58px !important;
}
.wizard-icon-container {
  border-radius: 50% !important;
  background-color: rgb(251, 198, 55) !important;
}
.wizard-progress-bar {
  background-color: rgb(251, 198, 55) !important;
}
.wizard-progress-with-circle {
  top: 34px !important;
  background-color: black;
  opacity: 0.6 !important;
}
.wizard-icon {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
}
.stepTitle {
  color: black !important;
  font-weight: 700;
}
.wizard-btn {
  background-color: rgb(251, 198, 55) !important;
  border: none !important;
}
.previewImg {
  width: 100%;
  height: 100%;
}
.upload-with-preview {
  background-color: #f1f1f1;
  border: 1px dashed black;
  height: 148px;
  margin: 6px;
  overflow: hidden;
  border-radius: 12px;
}
.image-upload-error {
  border: 1px dashed red;
}
.take-pic {
  width: 68px;
  display: block;
  margin: auto;
  margin-top: 24px;
  border-radius: 50%;
  overflow: hidden;
}
</style>
